export default class TagModel {
  constructor(){
    this.id = null; // 태그 id
    this.name = ''; // 태그명
    this.title = ''; // 태그 타이틀
    this.description = ''; // 태그설명
    // this.createdAt = ''; // 생성일
    this.historyDataList = []; // 생성,수정 정보
  }
  setData(obj){
    const { id, name, title, description, createdAt, createdBy, updatedAt, updatedBy } = obj;
    this.id = id;
    this.name = name;
    this.title = title;
    this.description = description || '';
    this.historyDataList = [
      {
        by: createdBy,
        at: createdAt,
        type: '생성'
      },
      {
        by: updatedBy,
        at: updatedAt,
        type: '수정'
      }
    ]
  }
  getRegisterData(){
    let obj = {
      name : this.name,
      title : this.title,
      description : this.description,
    }
    return obj;
  }
  getModifyData(){
    let obj = {
      name : this.name,
      title : this.title,
      description : this.description,
    }
    return obj;
  }
}