import TagModel from '@/views/worldMap/tag/model/TagModel'
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils'
import { GET_REQUEST, POST_REQUEST, PUT_REQUEST, DELETE_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import router from '@/router';

export default class TagListViewModel {
  constructor() {
    this.tabData = {
      dataList:[
        { id:'ㄱ', text:'ㄱ' },
        { id:'ㄴ', text:'ㄴ' },
        { id:'ㄷ', text:'ㄷ' },
        { id:'ㄹ', text:'ㄹ' },
        { id:'ㅁ', text:'ㅁ' },
        { id:'ㅂ', text:'ㅂ' },
        { id:'ㅅ', text:'ㅅ' },
        { id:'ㅇ', text:'ㅇ' },
        { id:'ㅈ', text:'ㅈ' },
        { id:'ㅊ', text:'ㅊ' },
        { id:'ㅋ', text:'ㅋ' },
        { id:'ㅌ', text:'ㄷ' },
        { id:'ㅍ', text:'ㅍ' },
        { id:'ㅎ', text:'ㅎ' },
      ],
      option:{
        isCount: false
      }
    };
    this.selectedTabId = 'ㄱ';
    this.tagBoardData = {
      title:'태그목록',
      drawDataList: [
        {
          title:'No.',
          width:'80px',
          value:'id',
          isSorting: true
        },
        {
          title:'태그',
          width:'25%',
          value:'name',
          isAlignLeft: true,
          valueCustom:[
            { 
              type: 'text',
              value: '#'
            },
            {
              type: 'value',
              value:'name',
            }
          ],
          badge:{}
        },
        {
          title:'타이틀',
          width:'25%',
          value:'title',
          emptyValueText:'-',
          isAlignLeft: true,
        },
        {
          title:'설명',
          width:'',
          value:'description',
          emptyValueText:'-',
          isAlignLeft: true,
        },
      ],
      option:{
        isTotal: true,
        isSize: true
      }
    };
    this.tagDataList = [];
    this.isTagRegisterPopup = false;
    this.model = new TagModel();
    this.registerValid = {
      name:{
        value: 'name',
        valueType:'',
        isValid:false,
      },
    }
    // search
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
  }

  init(){
    this.getTagList();
  }
  // onUpdateTab(){

  // }
  onSearch(){
    this.getTagList();
  }
  onClickAdd(){
    this.isTagRegisterPopup = true;
  }
  registerValidatedResult(){
    return pageValidated(this.registerValid, this.model)
  }
  onClickTagRegisterPopupClose(){
    this.resetTagModel();
  }
  onClickTagRegisterPopupComplete(){
    const isModify = Boolean(this.model.id);
    if(isModify){
      this.putTagData(this.model.id);
    }else{
      this.postTagData();
    }
  }
  onClickTagDelete(id){
    const requestDelete = () => {
      this.deleteTagData(id);
      this.isTagRegisterPopup = false;
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `태그를 정말 삭제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  resetTagModel(){
    this.isTagRegisterPopup = false;
    this.model = new TagModel();
  }
  onClickRow(rowId){
    // router.push({ name: 'CONTENTS_PLACE_DETAIL', params: { 'id' : rowId } });
    this.getTagData(rowId);
    this.isTagRegisterPopup = true;
  }

  getTagList(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.TAG_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.tagDataList = resultData.data
      this.paginationData.totalCount = resultData.total;
      this.paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  getTagData(id){
    const data = this.tagDataList.find(item => item.id === id);
    this.model.setData(data);
  }
  postTagData(){
    const path = `${apiPath.TAG_REGISTER}`;
    const data = this.model.getRegisterData();
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', `태그가 추가되었습니다.`);
      this.resetTagModel();
      this.getTagList();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  putTagData(id){
    const path = `${apiPath.TAG_REGISTER}/${id}`;
    const data = this.model.getModifyData();
    PUT_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', `태그가 수정되었습니다.`);
      this.resetTagModel();
      this.getTagList();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}