<template>
  <Popup
    :title="tagData.id ? '태그 수정' : '새로운 태그 추가'"
    :maxWidth="800"
    closeBtnText="닫기"
    :isDisabeldCompeleteBtn="viewModel.registerValidatedResult()"
    compeleteBtnText="저장"
    @onClickClose="viewModel.onClickTagRegisterPopupClose()"
    @onClickComplete="viewModel.onClickTagRegisterPopupComplete()">
    <InfoDosc
      v-if="tagData.id"
      :dataList="tagData.historyDataList"/>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:170px"/>
        <col />
        <col style="width:170px"/>
        <col />
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>태그명<IconSvg iconName="essential" :size="6" iconColor="#F04848"/><span class="txt_info">(띄어쓰기 없음)</span></th>
          <td colspan="3">
            <Input
              placeholder="태그명"
              :value.sync="tagData.name"/>
          </td>
        </tr>
        <tr>
          <th>타이틀</th>
          <td colspan="3">
            <Input
              placeholder="태그타이틀"
              :value.sync="tagData.title"/>
          </td>
        </tr>
        <tr>
          <th>태그설명</th>
          <td colspan="3">
            <Textarea
              placeholder="태그설명"
              :value.sync="tagData.description"
              :max="100"
              :showLine="4"
              :isCount="true"/>
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import InfoDosc from '@lemontree-ai/lemontree-admin-common-front/components/common/infoDosc/InfoDosc';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import { getSelectDataList } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils'

export default {
  name:'CourseRegisterPopup',
  components:{
    Popup,
    InfoDosc,
    TableView,
    IconSvg,
    Input,
    Textarea,
  },
  props:{
    viewModel: Object,
  },
  data(){
    return{
      isModify: false
    }
  },
  computed:{
    tagData(){
      return this.viewModel.model;
    },
    isDisabledPlace(){
      return this.tagData.id && this.tagData.status != 'DRAFT'
    },
    dependentCourseDataList(){
      let result = [
        { id:null, name:'없음' }
      ];
      result = result.concat(this.viewModel.contentsData.COURSE.dataList);
      if(this.tagData.id){
        result = result.filter(item => this.tagData.id != item.id);
      }
      result = result.map(item => {
        let rObj = {};
        rObj.id = item.id;
        rObj.text = `${item.place ? `[${item.place.name}] `:''}${item.name}`;
        return rObj;
      });
      return result;
    },
    audienceDataList(){
      return getSelectDataList('course_audience');
    },
    difficultyDataList(){
      return getSelectDataList('course_difficulty');
    }
  }
}
</script>