<template>
  <PageWithLayout>
    <Board
      :boardData="viewModel.tagBoardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.tagDataList"
      :paginationData="viewModel.paginationData"
      @onClickRow="rowId => viewModel.onClickRow(rowId)"
      @onSearch="viewModel.onSearch()">
      <!-- <template v-slot:tbl_tab>
        <Tab
          :tabData="viewModel.tabData"
          :value.sync="viewModel.selectedTabId"
          @update:value="value => $emit('onUpdateTab',value)"/>
      </template> -->
      <template v-slot:headRight>
        <Button
          btnSize="small"
          btnStyle="primary"
          text="새 태그 추가"
          @onClickBtn="viewModel.onClickAdd()">
          <IconSvg iconName="add" :size="12" iconColor="#fff" />
        </Button>
      </template>
    </Board>
    <template v-slot:popup>
      <TagRegisterPopup
        v-if="viewModel.isTagRegisterPopup"
        :viewModel="viewModel"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Tab from '@lemontree-ai/lemontree-admin-common-front/components/common/tab/Tab';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import TagRegisterPopup from '@/views/worldMap/tag/view/popup/TagRegisterPopup'
// viewModel
import TagViewModel from '@/views/worldMap/tag/viewModel/TagViewModel'

export default {
  name:'Tag',
  components:{
    PageWithLayout,
    Tab,
    Board,
    IconSvg,
    Button,
    TagRegisterPopup,
  },
  beforeMount(){
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new TagViewModel(),
    }
  },
}
</script>
<style scoped>
.board_comm::v-deep .tab_comm{max-width:800px}
.board_comm::v-deep .tab_comm .btn_tab{padding:7px 14px 7px}
/* 모바일 */
@media all and (max-width:1000px){
  .board_comm::v-deep .tab_comm{overflow:hidden;overflow-x:auto;width:100%;padding-bottom:10px;white-space:nowrap}
  .board_comm::v-deep .tab_comm .btn_tab{width:auto;padding:4px 8px 4px;border-radius:15px;border-bottom:1px solid #e1e1e1;line-height:20px}
  .board_comm::v-deep .tab_comm .btn_tab + .btn_tab{margin-left:8px}
  .board_comm::v-deep .tab_comm .btn_tab.active{margin-bottom:0;padding-bottom:4px;border-bottom:1px solid #222}
}
</style>